'use client';

import ModalFinalResults from './modalFinalResults';
import { useState } from 'react';
import BasicModal from 'components/modal/Modal';
import { Button } from '@chakra-ui/react';

function ModalFinal({ idGrade, idEst, student, resultsData }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <BasicModal isOpen={showModal} setIsOpen={setShowModal}>
        <ModalFinalResults
          idGrade={idGrade}
          idEst={idEst}
          setShowModal={setShowModal}
          student={student}
          resultsData={resultsData}
        />
      </BasicModal>

      <div className="flex justify-end items-center">
        <Button colorScheme="messenger" onClick={() => setShowModal(true)}>
          Ver notas finales
        </Button>
      </div>
    </>
  );
}

export default ModalFinal;
