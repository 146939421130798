import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import xhr from 'xhr';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function Promedio() {
  const navigate = useNavigate();
  const [students, setStudents] = React.useState([]);

  const getCurrentDate = dayjs().format('YYYY');

  const getDate = `${getCurrentDate}-01-01T00:00:00.000Z`;

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(
          `/student-notes?filters[period][$eq]=cuarto&populate=student.user&populate=grade&filters[createdAt][$gte]=${getDate}&pagination[limit]=-1`
        );
        setStudents(res.data.data);
      } catch (error) {}
    };
    getProgramTypes();
  }, []);

  const getBestStudents = (students) => {
    const studentsWithAverages = students.map((student) => {
      const totalScore = student.notes.reduce((acc, note) => acc + Number(note.score), 0);
      const averageScore = totalScore / student.notes.length;
      return { student: student, averageScore };
    });

    // Ordenar por promedio descendente
    const sortedStudents = studentsWithAverages.sort((a, b) => b.averageScore - a.averageScore);

    return sortedStudents;
  };

  const sortedStudents = getBestStudents(students);

  // Mostrar al estudiante con mejores notas
  console.log('Estudiantes ordenados por promedio:', sortedStudents);
  console.log('Mejor estudiante:', sortedStudents[0]);

  console.log(students);

  return (
    <>
      <Box>Lista de mejores estudiantes del cuarto periodo del {getCurrentDate}</Box>
      <TableContainer mt="10px">
        <Table variant="simple">
          <Thead bg="gray.200">
            <Tr>
              <Th>ID</Th>
              <Th>Nombre</Th>
              <Th>Apellido</Th>
              <Th>Grado</Th>
              <Th>Nota</Th>
              <Th>Ver</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedStudents.map((el, index) => {
              return (
                <Tr>
                  <Td>{el?.student?.student?.id}</Td>
                  <Td>{el?.student?.student?.user?.first_name}</Td>
                  <Td>{el?.student?.student?.user?.last_name}</Td>
                  <Td>{el?.student?.grade.name}</Td>
                  <Td>{el?.averageScore}</Td>
                  <Td>
                    <Link to={`/plataforma/administrador/ver-estudiante/${el?.student?.student?.id}`}>
                      <a>Ver</a>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Promedio;
