import { Box, Button, Checkbox, Flex, Spinner, Switch, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import xhr from 'xhr';

function Month({
  idStudent,
  type,
  currentEnrollment,
  fetching,
  setMonths,
  months,
  setMonthStudent,
  monthStudent,
  program,
  currentProgramType,
  insert,
  setIsOpen,
  checked,
  setChecked,
}) {
  const [isFetching, setIsFetching] = useState(true);
  const [enrollment, setEnrollment] = useState(true);
  const [updating, setUpdating] = useState(false);
  const toasts = useToast();
  if (!type) return <>No hay program_type</>;
  if (type === 'course') return <>Este pago es de un curso</>;
  if (!program === 'course') return <>No hay program</>;

  let all = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' },
  ];
  const [allMonths, setAllMonths] = useState();

  const handleCheck = (item, checked) => {
    let newConceptList;

    if (checked) {
      newConceptList = [...months, { ...item, nosave: true }];
    } else {
      newConceptList = months.filter((el) => el.id !== item.id);
    }

    setMonths(newConceptList);
  };
  useEffect(() => {
    (async () => {
      if (currentEnrollment) {
        const res = await xhr(
          `/month-students?filters[student][id][$eq]=${idStudent}&filters[${type}_enrollment][id][$eq]=${currentEnrollment.id}&populate=*`
        );
        setMonthStudent(res.data.data.length > 0 ? res.data.data[0] : []);
        setMonths(
          res.data.data[0]?.months
            ? res.data.data[0]?.months?.map((el) => {
                return { id: el.id, name: el.name };
              })
            : []
        );
        setChecked(res.data.data.length > 0 ? res.data.data[0]?.on_day : false);
        let semester = currentEnrollment.semester?.name;
        if (semester) {
          let ultimosDigitos = semester.slice(-2);

          const semster1 = months.slice(0, 6);
          const semster2 = months.slice(6, 12);

          // Verificar si los últimos dos dígitos son 01 o 02
          if (ultimosDigitos === '01' || ultimosDigitos === '02') {
            semester = ultimosDigitos;
          }

          if (semester === '01') {
            setAllMonths(all.slice(0, 6));
          } else if (semester === '02') {
            setAllMonths(all.slice(6, 12));
          } else {
            setAllMonths(all);
          }
        }

        setIsFetching(false);
      } else if (insert) {
        const resCurrentEnrollment = await xhr(
          `/${type.replace('_', '-')}-enrollments?filters[student][id][$eq]=${idStudent}&filters[${type}][id][$eq]=${
            program.id
          }&filters[${type}][program_type][id][$eq]=${
            currentProgramType.id
          }&populate=${type}&populate=semester&sort[0]=updatedAt:desc`
        );
        setEnrollment(resCurrentEnrollment.data.data[0]);
        const currentEnrollment = resCurrentEnrollment.data.data[0];

        const res = await xhr(
          `/month-students?filters[student][id][$eq]=${idStudent}&filters[${type}_enrollment][id][$eq]=${currentEnrollment.id}&populate=*`
        );
        setMonthStudent(res.data.data.length > 0 ? res.data.data[0] : []);
        setChecked(res.data.data.length > 0 ? res.data.data[0]?.on_day : false);
        setMonths(
          res.data.data[0]?.months
            ? res.data.data[0]?.months?.map((el) => {
                return { id: el.id, name: el.name };
              })
            : []
        );
        let semester = currentEnrollment.semester?.name;
        if (semester) {
          let ultimosDigitos = semester.slice(-2);

          const semster1 = months.slice(0, 6);
          const semster2 = months.slice(6, 12);

          // Verificar si los últimos dos dígitos son 01 o 02
          if (ultimosDigitos === '01' || ultimosDigitos === '02') {
            semester = ultimosDigitos;
          }

          if (semester === '01') {
            setAllMonths(all.slice(0, 6));
          } else if (semester === '02') {
            setAllMonths(all.slice(6, 12));
          } else {
            setAllMonths(all);
          }
        }
        setIsFetching(false);
      }
    })();
  }, [currentEnrollment]);

  const handleUpdate = async () => {
    setUpdating(true);
    if (monthStudent.length === 0) {
      await xhr.post(`/month-students/`, {
        data: {
          student: idStudent,
          [`${type}_enrollment`]: currentEnrollment ? currentEnrollment?.id : enrollment.id,
          on_day: checked,
          months: months.map((el) => el.id),
        },
      });
    } else {
      await xhr.put(`/month-students/${monthStudent?.length > 0 ? monthStudent[0].id : monthStudent.id}`, {
        data: {
          on_day: checked,
          months: months.map((el) => el.id),
        },
      });
    }
    toasts({
      title: 'los meses se actualizaron correctamente!',
      accent: 'bottom',
    });
    setIsOpen(false);
    setUpdating(false);
    setChecked(false);
  };
  return (
    <Box marginBottom="20px" marginTop="10px" border="1px solid #e2e8f0" borderColor="white900" borderTopRadius="5px">
      <Box
        color="purple.700"
        fontWeight="500"
        paddingY="8px"
        paddingX="15px"
        backgroundColor="purple.200"
        borderTopRadius="5px"
      >
        ¿Qué meses pagó el estudiante?
      </Box>

      {isFetching || fetching ? (
        <Flex justifyContent="center" paddingY="lg">
          <Spinner />
        </Flex>
      ) : currentEnrollment === false && !enrollment ? (
        <Box>Este enrollment no existe </Box>
      ) : !currentEnrollment?.semester && !enrollment.semester ? (
        <Box>Este enrollment no ha sido asignado a un semestre aún </Box>
      ) : (
        <Box display="grid" gridTemplateColumns="1fr 1fr" padding="15px" gridGap="12px">
          {allMonths?.map((el) => {
            const isEnrolled = months.some((month) => month.id === el.id && !month.nosave);
            return (
              <Box>
                <Checkbox
                  isDisabled={isEnrolled}
                  textDecoration={isEnrolled && 'line-through'}
                  cursor={isEnrolled ? 'not-allowed' : 'pointer'}
                  defaultChecked={isEnrolled}
                  onChange={(event) => handleCheck(el, event.target.checked)}
                >
                  {el.name}
                </Checkbox>
              </Box>
            );
          })}
          <Box flex gap={3}>
            <p>Está al día este estudiante??</p>
            <Switch defaultChecked={checked} onChange={(e) => setChecked(!checked)} />
          </Box>
          {insert && (
            <Button
              colorScheme="blue"
              size={'sm'}
              mt="10px"
              onClick={handleUpdate}
              isDisabled={updating}
              isLoading={updating}
            >
              Actualizar
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Month;
