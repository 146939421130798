import React, { useEffect, useState } from 'react';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import xhr from 'xhr';

export default function InsertNotes(props) {
  const { currentPeriod, id_est, idGrade, currentNotesPeriod, results } = props;
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [studentNotes, setStudentNotes] = useState([]);
  const toasts = useToast();
  useEffect(() => {
    (async () => {
      const res = await xhr(
        `/student-notes?filters[student][id][$eq]=${id_est}&filters[period][$eq]=${currentPeriod}&filters[grade][id][$eq]=${idGrade}`
      );
      setStudentNotes(res.data.data);
      setIsFetching(false);
    })();
  }, [currentPeriod]);

  const insertScore = async () => {
    setLoading(true);

    if (studentNotes.length === 0) {
      const update = await xhr.post(`/student-notes`, {
        data: {
          student: id_est,
          grade: idGrade,
          period: currentPeriod,
          notes: currentNotesPeriod.map((el) => ({
            subject: el.subject.name.split(/periodo/i)[0].trim(),
            intensity: el.subject?.time_intensity,
            score: el.recuperationQuizEnrollment
              ? Number(el.recuperationQuizEnrollment?.note / 10).toFixed(1) < el.final_score.score
                ? el.final_score.score
                : (Number(el.recuperationQuizEnrollment?.note) / 10).toFixed(1)
              : Number(el.enrollment?.extra_score)
              ? el.enrollment?.extra_score
              : Number(
                  el.enrollment?.extra_final_note ? el.enrollment?.extra_final_note.toFixed(2) : el.final_score.score
                ),
          })),
        },
      });

      setStudentNotes([update.data.data]);
    } else {
      const update = await xhr.put(`/student-notes/${studentNotes[0].id}`, {
        data: {
          student: id_est,
          grade: idGrade,
          period: currentPeriod,
          notes: currentNotesPeriod.map((el) => ({
            subject: el.subject.name.split(/periodo/i)[0].trim(),
            intensity: el.subject?.time_intensity,
            score: el.recuperationQuizEnrollment
              ? Number(el.recuperationQuizEnrollment?.note / 10).toFixed(1) < el.final_score.score
                ? el.final_score.score
                : (Number(el.recuperationQuizEnrollment?.note) / 10).toFixed(1)
              : Number(el.enrollment?.extra_score)
              ? el.enrollment?.extra_score
              : Number(
                  el.enrollment?.extra_final_note ? el.enrollment?.extra_final_note.toFixed(2) : el.final_score.score
                ),
          })),
        },
      });
      setStudentNotes([update.data.data]);
    }

    toasts({
      status: 'success',
      title: 'Las notas se insertaron correctamente!',
      accent: 'bottom',
    });

    setLoading(false);
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <Button
          ml="10px"
          colorScheme="messenger"
          isLoading={loading}
          isDisabled={loading}
          onClick={() => {
            window.confirm(
              `Está seguro de que desea ${
                studentNotes.length === 0 ? 'insertar' : 'actualizar'
              } las notas de este periodo?`
            ) && insertScore();
          }}
        >
          {studentNotes.length === 0 ? 'Insertar' : 'Actualizar'} notas finales del {currentPeriod} periodo
        </Button>
      )}
    </>
  );
}
